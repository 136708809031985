import request from '@/utils/request'

// 查询客户收货地址
export function findReceiveAddresses(params) {
  return request({
    url: `/receive_addresses`,
    method: 'get',
    params: params
  })
}

// 查询单个收货地址表单值
export function findReceiveAddressForm(id) {
  return request({
    url: `customer/receive_addresses/${id}/form`,
    method: 'get'
  })
}

// 更新客户收货地址
export function updateReceiveAddress(id, data) {
  return request({
    url: `customer/receive_addresses/${id}`,
    method: 'put',
    data
  })
}

// 创建收货地址
export function createReceiveAddress(data) {
  return request({
    url: `customer/receive_addresses`,
    method: 'post',
    data
  })
}

// 设置默认收货地址
export function SetReceiveAddressDefault(id) {
  return request({
    url: `customer/receive_addresses/${id}/default`,
    method: 'patch'
  })
}

// 删除收货地址
export function DeleteReceiveAddress(id) {
  return request({
    url: `customer/receive_addresses/${id}`,
    method: 'delete'
  })
}
