<template>
  <div>
    <div class="iotplt-card-box card-margin-bottom">
      <h4 class="title">收货信息</h4>
      <agent-purchase-item-address @completed="handleReceiveInfo" />
    </div>

    <div class="iotplt-card-box item-list card-margin-bottom">
      <h4 class="title">订单项目</h4>
      <a-table
        size="middle"
        :columns="columns"
        :data-source="items"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 'max-content' }"
        :custom-row="customItemRow"
        row-key="id"
      >
        <div
          slot="product_name"
          slot-scope="product_name, record"
        >
          <span v-if="record.valid">
            {{ product_name }}
          </span>
          <a-space v-else>
            <a-tooltip>
              <template slot="title">
                该产品或卡品不可采购
              </template>
              <a-icon type="warning" style="color: #ec5c5c" />
            </a-tooltip>
            {{ product_name }}
          </a-space>
        </div>
      </a-table>
    </div>

    <div class="iotplt-card-box card-margin-bottom">
      <h4 class="title">订单备注</h4>
      <a-textarea
        allow-clear
        :max-length="200"
        :auto-size="{ minRows: 3, maxRows: 5 }"
        @change="changeRemark"
      />
    </div>

    <div class="iotplt-card-box card-margin-bottom">
      <h4 class="title">用卡须知</h4>
      <a-tooltip
        placement="topLeft"
        :title="isDisabledCheckUseCardTip ? '请联系管理员维护物联网卡协议！' : ''"
        arrow-point-at-center
      >
        <a-checkbox
          @change="changeUseCardTip"
          :disabled="isDisabledCheckUseCardTip"
        >
          <span>阅读并接受</span>
          <a :href="agreement.resource_url" target="_blank">
            {{ agreement.name }}
          </a>
        </a-checkbox>
      </a-tooltip>

      <p>
        移动、联通、电信、广电四大运营商联合开展物联网卡高危地区管控：云南，新疆，西藏，缅北，海南，广西，福建。
        请规范使用物联网卡，一旦在高危地区使用，可能导致停卡甚至销卡的情况。我司不负责退款退卡，所有责任自行承担。
      </p>
    </div>

    <div class="iotplt-card-box">
      <a-descriptions
        :column="1"
        class="iotplt-descriptions iotplt-descriptions-max-6"
      >
        <a-descriptions-item label="可用额度(元)">
          {{ data.available | formatCurrency }}
        </a-descriptions-item>
        <a-descriptions-item label="订单金额(元)">
          {{ data.order_fee | formatCurrency }}
        </a-descriptions-item>
        <a-descriptions-item label="订单卡数(张)">
          {{ data.order_card_count | formatBigNumber }}
        </a-descriptions-item>
      </a-descriptions>
      <a-button
        type="primary"
        :loading="submitting"
        @click="handleSubmit"
      >确定订单</a-button>
    </div>
  </div>
</template>

<script>
import { findUnsettledAgentPurchaseItems } from '@/api/agent_purchase_item'
import { createAgentPurchaseOrder } from '@/api/agent_purchase_order'
import { findUsingCardAgreement } from '@/api/agreement'
import AgentPurchaseItemAddress from '@/views/agent_purchase_items/cart/settlement/address/index'
import { formatFloat } from '@/utils/filter'

export default {
  name: 'SettlementAgentPurchaseItem',
  components: {
    AgentPurchaseItemAddress
  },
  data() {
    return {
      data: [],
      items: [],
      agreement: {},
      loading: true,
      submitting: false,
      receiveInfo: {},
      remark: '',
      isCheckUseCardTip: false,
      isDisabledCheckUseCardTip: true,
      query: {}
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '套餐名称',
          dataIndex: 'product_name',
          scopedSlots: { customRender: 'product_name' },
          fixed: 'left'
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          title: '用户业务模式',
          dataIndex: 'user_business_type'
        },
        {
          title: '套餐周期',
          dataIndex: 'service_cycle'
        },
        {
          title: '套餐容量',
          dataIndex: 'package_capacity'
        },
        {
          title: '语音',
          dataIndex: 'voice_capacity'
        },
        {
          title: '卡品',
          dataIndex: 'card_type'
        },
        {
          title: '服务周期数(个)',
          dataIndex: 'service_period'
        },
        {
          title: '采购卡数(张)',
          dataIndex: 'card_count'
        },
        {
          title: '套餐价格(元)',
          dataIndex: 'distributor_price',
          customRender: formatFloat,
          scopedSlots: { customRender: 'distributor_price' }
        },
        {
          title: '卡板价格',
          dataIndex: 'card_type_price'
        },
        {
          title: '套餐费用(元)',
          dataIndex: 'product_fee',
          customRender: formatFloat,
          scopedSlots: { customRender: 'product_fee' }
        },
        {
          title: '卡板费用(元)',
          dataIndex: 'card_fee',
          customRender: formatFloat,
          scopedSlots: { customRender: 'card_fee' }
        },
        {
          title: '小计(元)',
          dataIndex: 'total_fee',
          customRender: formatFloat,
          scopedSlots: { customRender: 'total_fee' }
        },
        {
          title: '备注',
          width: 150,
          dataIndex: 'remark',
          ellipsis: true
        }
      ]
    },

    ids() {
      return this.$route.query.ids
    }
  },
  created() {
    this.fetchData()
    this.fetchAgreement()
  },
  methods: {
    // 自定义项目行样式
    customItemRow(record) {
      return {
        style: {
          color: record.valid ? '' : '#c7c7c7' // 无效的项目字体颜色
        }
      }
    },

    fetchAgreement() {
      this.isDisabledCheckUseCardTip = true
      findUsingCardAgreement().then((res) => {
        if (res.code === 0) {
          this.isDisabledCheckUseCardTip = false
          this.agreement = res.data
          this.agreement.name = `《${this.agreement.name}》`
        }
      })
    },

    fetchData() {
      this.loading = true
      findUnsettledAgentPurchaseItems(Object.assign(
        { agent_id: this.$store.getters.userAgentId, ids: this.ids.split(',') },
        this.query)
      ).then((res) => {
        this.data = res.data
        this.items = this.data.agent_purchase_items
        this.loading = false
      })
    },

    changeRemark(e) {
      this.remark = e.target.value
    },

    changeUseCardTip(e) {
      this.isCheckUseCardTip = e.target.checked
    },

    handleReceiveInfo(receiveInfo) {
      this.receiveInfo = receiveInfo
    },

    receiveInfoError() {
      this.$error({
        title: '提示',
        content: '请完善收货信息'
      })
    },

    useCardTipError() {
      this.$error({
        title: '提示',
        content: '请勾选用卡须知'
      })
    },

    handleSubmit() {
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (Object.keys(this.receiveInfo).length === 0) {
        this.receiveInfoError()
        return
      }

      if (!this.isCheckUseCardTip) {
        this.useCardTipError()
        return
      }

      var itemIds = this.ids.split(',').map(function(itemId) {
        return parseInt(itemId, 10)
      })

      this.submitting = true
      const data = Object.assign(
        {
          agent_id: this.$store.getters.userAgentId,
          item_ids: itemIds,
          remark: this.remark,
          agreement_id: this.agreement.id
        },
        this.receiveInfo)
      createAgentPurchaseOrder(data).then((res) => {
        if (res.code === 0) {
          this.$router.push({ name: 'agent_purchase_orders' })
        }
        this.submitting = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.card-margin-bottom{
  margin-bottom: 20px;
}

.title{
  color: @primary-color;
}
</style>
