<template>
  <div>
    <a-row>
      <a-radio-group
        name="pickup_way"
        default-value="post"
        @change="changePickupWay"
      >
        <a-radio value="post">
          商家邮寄
        </a-radio>
        <a-radio value="self_take">
          客户自提
        </a-radio>
      </a-radio-group>
      <a-popover trigger="hover">
        <template slot="content">
          <p>*如果您选择商家邮寄，平台将为您选择包邮或到付的方式进行快递寄送，若有特殊要求请在订单备注中说明。</p>
          <p>*如果您选择客户自提，平台将不提供邮寄服务，需要您联系销售人员，前往指定的地点去提取订购的物联卡。</p>
        </template>
        <a-space>
          <a-icon type="question-circle" />
        </a-space>
      </a-popover>
    </a-row>
    <a-row v-if="isSelfTake" class="self-tale-info">
      <a-descriptions
        :column="1"
        class="iotplt-descriptions"
      >
        <a-descriptions-item label="联系人">
          客服专员
        </a-descriptions-item>

        <a-descriptions-item label="电话">
          400-000-0000
        </a-descriptions-item>

        <a-descriptions-item label="时间">
          每周一至周五 上午 9：00 ~ 下午 17：30
        </a-descriptions-item>
      </a-descriptions>
    </a-row>

    <a-row style="margin-top: 10px" v-if="!isSelfTake">
      <agent-purchase-item-address-search @submit="submitSearch" />
    </a-row>

    <a-row style="margin-top: 10px" v-if="!isSelfTake">
      <div class="iotplt-table-operation-buttons">
        <a-space>
          <a-button type="primary" @click="isShowNewModal = true">新增</a-button>
        </a-space>
      </div>
      <a-table
        size="middle"
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :pagination="false"
        :row-selection="{ type: 'radio', selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        row-key="id"
      >
        <span slot="actions" slot-scope="text, record">
          <a-space>
            <a @click="showEditModal(record.id)">编辑</a>
          </a-space>
        </span>
      </a-table>

      <pagination
        v-show="pagination.total_count > 0"
        :total-count="pagination.total_count"
        :page.sync="query.page"
        :per-page.sync="query.per_page"
        @change="fetchData"
      />

      <!-- 新增模态框 -->
      <new-address
        v-if="isShowNewModal"
        :visible.sync="isShowNewModal"
        @completed="fetchData"
      />

      <!-- 编辑模态框 -->
      <edit-address
        v-if="isShowEditModal"
        :visible.sync="isShowEditModal"
        :id="editingId"
        @completed="fetchData"
      />
    </a-row>
  </div>
</template>

<script>
import { findReceiveAddresses } from '@/api/receive_address'
import AgentPurchaseItemAddressSearch from '@/views/agent_purchase_items/cart/settlement/address/Search'
import Pagination from '@/components/Pagination'

export default {
  name: 'AgentPurchaseItemAddresses',
  components: {
    Pagination,
    AgentPurchaseItemAddressSearch,
    NewAddress: () => import('@/views/receive_addresses/New'),
    EditAddress: () => import('@/views/receive_addresses/Edit')
  },
  data() {
    return {
      data: [],
      chooseRowKeys: 0,
      isSelfTake: false,
      loading: true,
      defaultAddressId: 0,
      editingId: 0,
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowNewModal: false, // 是否显示新增表单弹窗
      selectedRowKeys: [],
      pickupWay: 'post',
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '收货人',
          dataIndex: 'receiver',
          fixed: 'left'
        },
        {
          title: '联系电话',
          dataIndex: 'phone_number'
        },
        {
          title: '邮编',
          dataIndex: 'post_code'
        },
        {
          title: '所在地区',
          dataIndex: 'region_display'
        },
        {
          title: '详细地址',
          dataIndex: 'address_detail'
        },
        {
          title: '操作',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' },
          fixed: 'right'
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    showEditModal(recordId) {
      this.editingId = recordId
      this.isShowEditModal = true
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      if (this.selectedRowKeys.length > 0) {
        this.complete()
      }
    },

    changePickupWay(e) {
      this.pickupWay = e.target.value
      this.isSelfTake = e.target.value === 'self_take'
      if (this.isSelfTake) {
        this.complete()
      }
    },

    complete() {
      if (this.pickupWay === 'post') {
        this.$emit('completed', { pickup_way: 'post', address_id: this.selectedRowKeys[0] })
      } else {
        this.$emit('completed', { pickup_way: 'self_take' })
      }
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findReceiveAddresses(
        Object.assign({ agent_id: this.$store.getters.userAgentId }, this.query)
      ).then((res) => {
        this.data = res.data
        this.data.forEach((address) => {
          if (address.is_default) {
            this.onSelectChange([address.id])
          }
        })
        this.pagination = res.pagination
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.self-tale-info {
  margin-top: 20px;
}
</style>
