import request from '@/utils/request'

// 创建订单
export function createAgentPurchaseOrder(data) {
  return request({
    url: `/customer/agent_purchase_orders`,
    method: 'post',
    data
  })
}

// 查询采购订单
export function findAgentPurchaseOrders(params) {
  return request({
    url: `/agent_purchase_orders`,
    method: 'get',
    params: params
  })
}

// 修改订单收货地址
export function modifyAgentPurchaseAddress(id, data) {
  return request({
    url: `/customer/agent_purchase_orders/${id}/modify_address`,
    method: 'patch',
    data
  })
}

// 取消订单
export function cancelAgentPurchaseOrder(id, data) {
  return request({
    url: `/customer/agent_purchase_orders/${id}/cancel`,
    method: 'patch',
    data
  })
}

// 查询订单状态
export function findAgentPurchaseOrderStatusOptions() {
  return request({
    url: `/agent_purchase_orders/status_options`,
    method: 'get'
  })
}

// 查询订单详情
export function findAgentPurchaseOrder(id) {
  return request({
    url: `/agent_purchase_orders/${id}`,
    method: 'get'
  })
}
