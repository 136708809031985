import request from '@/utils/request'

// 创建采购订单
export function createAgentPurchaseItem(data) {
  return request({
    url: `/customer/agent_purchase_items`,
    method: 'post',
    data
  })
}

// 查询费用结果
export function calculateAgentPurchaseItemFee(params) {
  return request({
    url: `/customer/agent_purchase_items/calculate_fee`,
    method: 'get',
    params: params
  })
}

// 查询购物车信息
export function findCartInfo(params) {
  return request({
    url: `/customer/cart`,
    method: 'get',
    params: params
  })
}

// 查询购物车信息
export function findCartItemsCount() {
  return request({
    url: `/customer/cart/items_count`,
    method: 'get'
  })
}

// 查询客户未结算项目
export function findUnsettledAgentPurchaseItems(params) {
  return request({
    url: `/customer/agent_purchase_items/unsettled`,
    method: 'get',
    params: params
  })
}

// 更新采购项目金额
export function updateAgentPurchaseItemFee(id, data) {
  return request({
    url: `/customer/agent_purchase_items/${id}/fee`,
    method: 'patch',
    data
  })
}

// 查询单个采购项目
export function findAgentPurchaseItem(id) {
  return request({
    url: `/agent_purchase_items/${id}`,
    method: 'get'
  })
}

// 批量删除客户
export function deleteAgentPurchaseItems(data) {
  return request({
    url: `/customer/agent_purchase_items/batch`,
    method: 'delete',
    data: data
  })
}

