import request from '@/utils/request'

// 查询在用的物联网卡协议
export function findUsingCardAgreement() {
  return request({
    url: `/agreements/card/using`,
    method: 'get'
  })
}

// 查询在用的平台服务协议
export function findUsingPlatformServiceAgreement(params) {
  return request({
    url: `/agreements/platform_service/using`,
    method: 'get',
    params: params
  })
}

// 客户同意服务协议
export function updateAgentPlatformServiceAgreement(data) {
  return request({
    url: `/agreements/platform_service/agent`,
    method: 'patch',
    data
  })
}
